











import {Component, Prop, Vue} from 'vue-property-decorator';
    import QrcodeVue from 'qrcode.vue'

    @Component({
        components: {QrcodeVue}
    })

    export default class QrPageIndividual extends Vue {

        @Prop(String) readonly erpcid: string|undefined;
      
        private erpcname: string = "";
        
        private link: string = "";

        mounted(){
          this.link = `https://apiuser.gen11project.com/usermanager/SaveQRLinkIndividual/${this.erpcid}`;
        }
        
    }
